import React from 'react';

import useIsMobile from 'hooks/useIsMobile';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppCardCarousel from 'components/AppCardCarousel/AppCardCarousel';

import styles from './styles/HomeMagazineArticles.module.scss';

export interface HomeMagazineArticlesProps {
  articles: {
    id: number;
    title: string;
    author: string;
    date: string;
    link: string;
    picture: string;
    description: string;
    categories: string[];
  }[];
  className?: string;
}

export const HomeMagazineArticles: React.FC<HomeMagazineArticlesProps> = ({
  articles,
  className,
}: HomeMagazineArticlesProps) => {
  const { mobileWidthDetected } = useIsMobile();

  return (
    <div className={`${styles['magazine-articles']} ${className}`}>
      <div className={styles['magazine-articles__interior']}>
        <h2 className={styles['magazine-articles__title']}>Recent Articles</h2>
        <AppCardCarousel
          className={styles['magazine-articles__carousel']}
          articles={articles}
          hasButtons={mobileWidthDetected}
        />
        <div className={styles['magazine-articles__button-container']}>
          <AppButtonLink
            className={styles['magazine-articles__button']}
            ghost
            label="View More"
            path={`https://thedyrt.com/magazine/`}
            dataEvent="click_view_more_articles"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeMagazineArticles;
