import React from 'react';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import * as amplitude from '@amplitude/analytics-browser';

import { NAV_BANNER_AD_SIZES } from 'constants/adSizes';
import { HOMEPAGE_PAGE_VIEW } from 'constants/amplitude';

import { DatesPopoverContextProvider } from 'contexts/datesPopover';

import useMountEffect from 'hooks/useMountEffect';

import AppPage from 'components/AppPage/AppPage';
import HomepageGoogleAd from 'components/GoogleAds/HomepageGoogleAd';
import ResponsiveNonProGoogleAd from 'components/GoogleAds/ResponsiveNonProGoogleAd';
import _BookableCampgrounds from 'components/Home/_BookableCampgrounds';
import _HomeClaim from 'components/Home/_HomeClaim';
import _HomeHeader from 'components/Home/_HomeHeader';
import _HomeLinkList from 'components/Home/_HomeLinkList';
import _PublicCampgrounds from 'components/Home/_PublicCampgrounds';
import HomeMagazineArticles from 'components/HomeMagazineArticles/HomeMagazineArticles';

const AMPLITUDE_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_KEY;
const WPAPI = process.env.NEXT_PUBLIC_WP_API;

type HomePageProps = InferGetServerSidePropsType<typeof getServerSideProps>;

const HomePage: React.FC<HomePageProps> = ({ magazineArticles }) => {
  useMountEffect(() => {
    if (AMPLITUDE_KEY) {
      amplitude.track(HOMEPAGE_PAGE_VIEW);
    }

    document.body.classList.add('branch-banner--is-hidden');
    return () => {
      document.body.classList.remove('branch-banner--is-hidden');
    };
  });

  return (
    <>
      <ResponsiveNonProGoogleAd
        sizes={NAV_BANNER_AD_SIZES}
        divId={'home-above-nav'}
        adType={'home-above-nav'}
        centered
      />
      <AppPage
        pageTitle="The Dyrt | Camping near me: Tent, RV sites, Glamping & Cabins"
        socialDescription="Find and book camping near me from over 12,000,000 locations, tips & photos submitted by campers like you."
        additionalMetaTags={[
          {
            property: 'keywords',
            content:
              'camping, best, rv, campsites, campgrounds, public, private',
          },
        ]}
        noPadding
        noFrame
        disableSearch
        content={
          <>
            <DatesPopoverContextProvider startDate={null} endDate={null}>
              <_HomeHeader />
            </DatesPopoverContextProvider>
            <_BookableCampgrounds />
            <HomepageGoogleAd />
            <HomeMagazineArticles articles={magazineArticles} />
            <_PublicCampgrounds />
            <_HomeClaim />
            <_HomeLinkList />
          </>
        }
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const magazineArticles: Article[] = await fetch(`${WPAPI}/posts?_embed`).then(
    (response) => response.json()
  );

  const transformedArticles = magazineArticles.slice(0, 4).map((article) => ({
    id: article.id,
    title: article.yoast_head_json.title,
    description: article.yoast_head_json.description,
    author: article.yoast_head_json.author,
    date: article.date,
    link: article.link,
    picture: article.jetpack_featured_media_url,
    categories: article._embedded['wp:term'][0]?.map((term) => term.name) || [
      'Uncategorized',
    ],
  }));

  return {
    props: {
      magazineArticles: transformedArticles,
    },
  };
};

export default HomePage;
