import React from 'react';

import {
  POPULAR_CITIES_CAMPING,
  POPULAR_PARKS_CAMPING,
  STATES_CAMPING,
} from 'constants/links';

import AppLink from 'components/AppLink/AppLink';
import AppTabNav from 'components/AppTabNav/AppTabNav';

import styles from './styles/_HomeLinkList.module.scss';

export interface Props {
  className?: string;
}

export interface LinkConfig {
  path: string;
  label: string;
  dataEvent: string;
}

export const _HomeLinkList: React.FC<Props> = ({ className }) => {
  const generateLists = (links: LinkConfig[]) => {
    return (
      <ul
        className={`${styles['links__list']} 
        ${styles['links__list-states']}`}
      >
        {links.map((item) => (
          <li className={styles['links__list-item']} key={item.path}>
            <AppLink href={item.path}>
              <a
                data-event={item.dataEvent}
                className={styles['links__list-item-link']}
              >
                {item.label}
              </a>
            </AppLink>
          </li>
        ))}
      </ul>
    );
  };

  const tabContent = [
    {
      label: 'Popular Cities',
      value: 'cities',
      display: generateLists(POPULAR_CITIES_CAMPING.links),
    },
    {
      label: 'Popular Parks',
      value: 'parks',
      display: generateLists(POPULAR_PARKS_CAMPING.links),
    },
    {
      label: 'All States',
      value: 'states',
      display: generateLists(STATES_CAMPING.links),
    },
  ];

  return (
    <div className={`${styles['links']} ${className}`}>
      <div className={styles['links__interior']}>
        <h3 className={styles['links__headline']}>Find Camping By Location</h3>
        <AppTabNav tabs={tabContent} defaultTab={0} />
      </div>
    </div>
  );
};

export default _HomeLinkList;
