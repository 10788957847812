import React, { useEffect, useState } from 'react';

import { DEFAULT_MAP_CENTER } from 'constants/map';

import { useUser } from 'contexts/currentUser';

import { isServer } from 'utils/isServer';

import useLoadRecords from 'hooks/useLoadRecords';

import AppCardCarousel from 'components/AppCardCarousel/AppCardCarousel';

import styles from './styles/_PublicCampgrounds.module.scss';

const _PublicCampgrounds: React.FC = () => {
  const { loadRecords } = useLoadRecords();

  const [campgrounds, setCampgrounds] = useState<CampgroundSearchResultModel[]>(
    []
  );

  const { userLocation } = useUser();

  useEffect(() => {
    if (!isServer && userLocation) {
      const abortController = new AbortController();

      const search = {
        'booking-method': 'ridb',
        recommended: '1',
      } as AppliedSearchFilter;

      if (userLocation !== DEFAULT_MAP_CENTER) {
        search.origin = `${userLocation.long},${userLocation.lat}`;
      }

      const getCampgrounds = async () => {
        loadRecords<CampgroundSearchResultModel>(
          'campground-search-results',
          {
            filter: {
              search,
            },
            page: {
              number: 1,
              size: 10,
            },
          },
          undefined,
          ({ data }) => {
            setCampgrounds(data);
          }
        );
      };

      getCampgrounds();

      return () => {
        abortController.abort();
      };
    }
  }, [loadRecords, userLocation]);

  return (
    <section className={styles['container']}>
      <div className={styles['content-container']}>
        <h2 className={styles['header']}>Public Campgrounds</h2>
        {!!campgrounds.length && (
          <AppCardCarousel hasBackground campgrounds={campgrounds} />
        )}
      </div>
    </section>
  );
};

export default _PublicCampgrounds;
