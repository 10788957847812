import React from 'react';

import ResponsiveNonProGoogleAd from './ResponsiveNonProGoogleAd';

import styles from './styles/GoogleAds.module.scss';

const sizes = [
  {
    adDimensions: [[970, 250]],
    screenDimensions: [1000, 300],
  },
  {
    adDimensions: [[728, 90]],
    screenDimensions: [788, 300],
  },
  {
    adDimensions: [[300, 250]],
    screenDimensions: [450, 300],
  },
  {
    adDimensions: [[300, 250], 'fluid'],
    screenDimensions: [350, 300],
  },
  {
    adDimensions: [],
    screenDimensions: [0, 0],
  },
] as ResponsiveAdSize[];

export const HomepageGoogleAd: React.FC = () => {
  return (
    <ResponsiveNonProGoogleAd
      sizes={sizes}
      divId="homepage-ad"
      adType="abf-leader"
      className={styles['homepage-ad']}
      showToPro={true}
    />
  );
};

export default HomepageGoogleAd;
