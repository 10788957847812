import React from 'react';

import styles from './styles/AppWaveClip.module.scss';

export interface AppWaveClipProps {
  className?: string;
  yOffset: number;
  amplitude: number;
  numWaves?: number;
  numPts?: number;
}

export const AppWaveClip: React.FC<AppWaveClipProps> = ({
  className,
  yOffset, // how far down to move the curve - in percentages
  amplitude, // if this changes, yOffset needs to change as well
  numWaves = 3,
  numPts = 100, // how many points in the curve
}: AppWaveClipProps) => {
  const getSinePath = () => {
    const sinePath = ['0 0']; // start - upper left

    for (let i = 0; i <= numPts; i++) {
      // includes numPts for x=100%
      const x = (100 * i) / numPts;
      const y =
        100 *
          (Math.cos(Math.PI + 2 * Math.PI * (i / numPts) * numWaves) *
            (amplitude * 0.01)) +
        yOffset;
      sinePath.push(`${x.toFixed(2)}% ${y.toFixed(2)}%`); // rounded to 2 places so the CSS doesn't look like garbage
    }

    sinePath.push('100% 100%'); // bottom right
    sinePath.push('0% 100%'); // bottom left

    const finalPath = `polygon(${sinePath})`;
    return finalPath;
  };

  return (
    <div
      className={`${styles['wave-clip']} ${className}`}
      style={{ clipPath: getSinePath() }}
    ></div>
  );
};

export default AppWaveClip;
