import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';

import { CAMPGROUND_SEARCH, PAGE_NAMES } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { convertDateToISOApiString } from 'utils/dates';
import getSearchLinkFromAutocomplete from 'utils/getSearchLinkFromAutocomplete';

import AppHamburger from 'components/AppHamburger/AppHamburger';
import AppSearchWithDates from 'components/AppSearchWithDates/AppSearchWithDates';
import AppWaveClip from 'components/AppWaveClip/AppWaveClip';

import styles from './styles/_HomeHeader.module.scss';

export interface Props {
  className?: string;
}

export const _HomeHeader: React.FC<Props> = ({ className }) => {
  const [searchLink, setSearchLink] = useState('');
  const [searchResult, setSearchResult] = useState<UnifiedAutocomplete | null>(
    null
  );

  const router = useRouter();

  const { pathname } = router;

  const captureDatePickerOpen = useRef(() => {});

  const onResultSelected = (
    result: UnifiedAutocomplete | null,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();

    const url = getSearchLinkFromAutocomplete(result);

    if (result) {
      setSearchResult(result);
    }

    if (result?.type === 'campground') {
      router.push(url);

      amplitude.track(CAMPGROUND_SEARCH, {
        'date search': false,
        'search location': 'home page',
        'search text': result?.name,
        'search type': result?.type,
        'check in date': null,
        'check out date': null,
      });
    } else {
      setSearchLink(url);
    }
  };

  useEffect(() => {
    if (searchLink) {
      captureDatePickerOpen.current();
    }
  }, [searchLink]);

  const sendCampgroundSearchEvent = useCallback(
    (dates: [Date | null, Date | null]) => {
      amplitude.track(CAMPGROUND_SEARCH, {
        'check in date': dates[0] ? convertDateToISOApiString(dates[0]) : false,
        'check out date': dates[1]
          ? convertDateToISOApiString(dates[1])
          : false,
        'date search': !!(dates[0] && dates[1]),
        'search location': PAGE_NAMES[pathname as PageKeys],
        'search type': searchResult
          ? searchResult.type === 'place'
            ? 'area'
            : searchResult.type
          : 'near me',
        'search text': searchResult?.name,
      });
    },
    [pathname, searchResult]
  );

  return (
    <div className={`${styles['hero']} ${className}`}>
      <div className={styles['hero__wrapper']}>
        <div className={styles['hero__content-container']}>
          <h2 className={styles['hero__headline']}>Camping Starts Here</h2>
          <h1 className={styles['hero__subhead']}>
            Book campgrounds for tents, RVs, cabins, and glamping, or find free
            camping
          </h1>

          <div className={styles['hero__content']}>
            <div className={styles['hero__drawer-container']}>
              <AppHamburger toggleLight />
            </div>

            <AppSearchWithDates
              onResultSelected={onResultSelected}
              targetSearchUrl={searchLink}
              startDate={null}
              endDate={null}
              yieldOpen={captureDatePickerOpen}
              sendEventOnSearchWithDates={sendCampgroundSearchEvent}
            />
          </div>
        </div>
        <div className={styles['hero__image-container']}>
          <Image
            src={`${PUBLIC_ASSETS_PATH}/assets/images/homepage/home_hero.png`}
            alt="Find camping across the US with The Dyrt."
            className={styles['hero__image']}
            width={370}
            height={332}
            quality={100}
            sizes="(max-width: 600px) 0vw, (max-width: 900px) 28vw, 370px"
            priority
          />
        </div>
      </div>
      <AppWaveClip
        numWaves={5}
        yOffset={14}
        amplitude={14}
        className={styles['hero__wave-clip']}
      />
    </div>
  );
};

export default _HomeHeader;
