import React, { useState } from 'react';

import styles from './styles/AppTabNav.module.scss';

export interface AppTabNavProps {
  className?: string;
  tabs: TabDisplayState[];
  onTabClick?: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    tab: TabDisplayState
  ) => void;
  defaultTab?: number;
}

export interface TabDisplayState {
  label: string;
  value: string;
  display: JSX.Element;
}

export const AppTabNav: React.FC<AppTabNavProps> = ({
  className,
  tabs,
  onTabClick,
  defaultTab = -1,
}: AppTabNavProps) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);

  const handleTabClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setCurrentTab(index);

    if (onTabClick) {
      onTabClick(e, tabs[index]);
    }
  };

  const tabItems = tabs.map((entry, index) => {
    return (
      <li
        className={`${styles['tabnav__nav-tab']} ${
          entry.label === tabs[currentTab]?.label
            ? styles['tabnav__nav-tab--is-active']
            : ''
        }`}
        key={entry.value}
        onClick={(e) => handleTabClick(e, index)}
        data-event="click_tab_nav"
      >
        {entry.label}
      </li>
    );
  });

  const displayItems = tabs.map((entry) => {
    return (
      <div
        className={`${styles['tabnav__display']} 
          ${
            tabs[currentTab]?.value === entry.value
              ? `${styles['tabnav__display--is-active']}`
              : ''
          }`}
        key={entry.value}
      >
        {entry.display}
      </div>
    );
  });

  return (
    <div className={`${styles['tabnav']} ${className}`}>
      <ul className={styles['tabnav__nav']}>{tabItems}</ul>
      <div className={styles['tabnav__frame']}>{displayItems}</div>
    </div>
  );
};

export default AppTabNav;
