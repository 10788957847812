import React, { useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { WEB_TO_APP_CLICK } from 'constants/amplitude';
import { DEFAULT_MAP_CENTER } from 'constants/map';

import { useUser } from 'contexts/currentUser';

import { isServer } from 'utils/isServer';
import { pluralize } from 'utils/pluralize';
import webToAppLink from 'utils/webToAppUrl';

import useIsMobileDevice from 'hooks/useIsMobileDevice';
import useLoadRecords from 'hooks/useLoadRecords';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppCampgroundCard from 'components/AppCampgroundCard/AppCampgroundCard';
import AppCardCarousel from 'components/AppCardCarousel/AppCardCarousel';
import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/_BookableCampgrounds.module.scss';

const _BookableCampgrounds: React.FC = () => {
  const { loadRecords } = useLoadRecords();

  const [campgroundData, setCampgroundData] = useState<{
    campgrounds: CampgroundSearchResultModel[];
    count: number;
  }>({ campgrounds: [], count: 0 });

  const { userLocation } = useUser();

  useEffect(() => {
    if (!isServer && userLocation) {
      const abortController = new AbortController();
      const search = {
        recommended: '1',
        bookable: 'instant,request',
      } as AppliedSearchFilter;
      if (userLocation !== DEFAULT_MAP_CENTER) {
        search.origin = `${userLocation.long},${userLocation.lat}`;
      }
      const getCampgrounds = async () => {
        loadRecords<CampgroundSearchResultModel>(
          'campground-search-results',
          {
            filter: {
              search,
            },
            page: {
              number: 1,
              size: 10,
            },
          },
          undefined,
          ({ data, meta }) => {
            setCampgroundData({
              campgrounds: data,
              count: meta?.recordCount || 0,
            });
          }
        );
      };
      getCampgrounds();
      return () => {
        abortController.abort();
      };
    }
  }, [loadRecords, userLocation]);

  const isMobileDevice = useIsMobileDevice();
  const featureParam = 'home_top';

  return (
    <section className={styles['container']}>
      <div className={styles['content']}>
        <h2 className={styles['header']}>Bookable Campgrounds</h2>
        <div className={styles['subheader']}>
          <h3>{`${campgroundData.count} ${pluralize(
            campgroundData.count,
            'locations',
            'location'
          )}`}</h3>
          {!!userLocation && (
            <span className={styles['subheader__location']}>
              <AppIcon icon="location_arrow" />
              <h3>{`${userLocation.city}`}</h3>
            </span>
          )}
        </div>
        {isMobileDevice && (
          <AppButtonLink
            className={styles['app-open-button']}
            label={'Open in The Dyrt app'}
            path={webToAppLink({ featureParam })}
            dataEvent={'home_page_web_to_app_button'}
            onClick={() => {
              amplitude.track(WEB_TO_APP_CLICK, {
                'page name': 'Home',
                'click label': 'Open in The Dyrt app',
              });
            }}
            fullWidth
          />
        )}
        <div className={styles['mobile-content']}>
          {campgroundData.campgrounds.slice(0, 4).map((campground, index) => (
            <AppCampgroundCard
              key={campground.id}
              campgroundOrLocation={campground}
              // eager load the first image because it is the largest contentful paint image on the home page on mobile
              lazyLoad={index > 0}
            />
          ))}
        </div>
        <div className={styles['desktop-content']}>
          {!!campgroundData.campgrounds.length && (
            <AppCardCarousel campgrounds={campgroundData.campgrounds} />
          )}
        </div>
      </div>
    </section>
  );
};

export default _BookableCampgrounds;
