import React from 'react';
import Image from 'next/image';
import * as amplitude from '@amplitude/analytics-browser';

import { CLAIM_CTA_CLICK } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';

import styles from './styles/_HomeClaim.module.scss';

export const _HomeClaim: React.FC = () => {
  const onLinkClick = () => {
    amplitude.track(CLAIM_CTA_CLICK, {
      'button name': 'Homepage - Claim a Campground',
      'page name': 'Home',
    });
  };
  return (
    <div className={styles['claim']}>
      <div className={styles['claim__text']}>
        <h2 className={styles['claim__headline']}>
          Own or Manage a Campground?
        </h2>
        <p className={styles['claim__copy']}>
          List your campground for free. Get access to the largest community of
          campers. Create or claim your listing in minutes.
        </p>

        <AppButtonLink
          className={styles['claim__link']}
          label="Claim A Campground"
          icon="arrow_right"
          path={'/claim'}
          ghost
          borderless
          dataEvent={`click_claim_campground`}
          onClick={onLinkClick}
        />
      </div>
      <Image
        src={`${PUBLIC_ASSETS_PATH}/assets/images/homepage/CampgroundOwner@2x.png`}
        alt="A hand holding a tent and a sun, depicting outdoor camping and sunny weather."
        className={styles['claim__image']}
        width={250}
        height={250}
      />
    </div>
  );
};

export default _HomeClaim;
